<!-- 支付二维码 -->
<template>
  <div class="orderCode" @click="handleOrderCode">
    <div class="code" @click.stop>
      <!-- <img
        src="../../assets/images/serviceclose.png"
        class="close"
        @click="handleOrderCode"
      /> -->
      <el-icon class="close" @click="handleOrderCode"><Close /></el-icon>
      <div class="top">
        <div class="title">
          <img src="../../assets/images/wex.svg" />微信支付
        </div>
        <div class="time" v-if="!remainingTimeFlag">
          请在{{ formatTime(remainingTime) }}内完成支付
        </div>
      </div>
      <div class="bot">
        <img :src="code" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs, reactive, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import QRCode from 'qrcode'
const store = useStore()
const code = ref('')
const orderCodeUrl = computed(() => store.state.common.orderCodeUrl)
const orderCodeTime = computed(() => store.state.common.orderCodeTime)
const vipTimer = computed(() => store.state.common.vipTimer)
const handleOrderCode = () => {
  store.commit('common/setOrderCode', false)
  clearInterval(timer.value)
  clearInterval(vipTimer.value)
}
const initData = reactive({
  remainingTime: 0,
  timer: null,
  remainingTimeFlag: false
})
const { remainingTime, timer, remainingTimeFlag } = toRefs(initData)

onMounted(() => {
  startTimer()
})

// 支付时间倒计时
const startTimer = () => {
  remainingTimeFlag.value = true
  setTimeout(() => {
    remainingTimeFlag.value = false
  }, 1000)
  timer.value = setInterval(() => {
    const currentTime = new Date().getTime()
    remainingTime.value = Math.max(0, orderCodeTime.value * 1000 - currentTime)
    if (remainingTime.value === 0) {
      clearInterval(timer.value)
    }
  }, 1000)
}
const formatTime = milliseconds => {
  const seconds = Math.floor((milliseconds / 1000) % 60)
  const minutes = Math.floor((milliseconds / 1000 / 60) % 60)
  const hours = Math.floor(milliseconds / 1000 / 60 / 60)

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')
  if (hours == 0) {
    return `${formattedMinutes}分${formattedSeconds}秒`
  }
  if (minutes == 0) {
    return `${formattedSeconds}秒`
  }
  if (hours != 0 && minutes != 0) {
    return `${formattedHours}小时${formattedMinutes}分${formattedSeconds}秒`
  }
}

onUnmounted(() => {
  clearInterval(timer.value)
})

// 生成二维码
QRCode.toDataURL(
  orderCodeUrl.value,
  { errorCorrectionLevel: 'H' },
  (err, url) => {
    if (err) {
      console.error('生成二维码失败', err)
      return
    }
    // 将生成的二维码 URL 显示在页面上
    code.value = url
  }
)
</script>
<style lang="scss" scoped>
.orderCode {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(82, 82, 82, 0.63);
  display: flex;
  justify-content: center;
  align-items: center;
  .code {
    width: 649px;
    height: 612px;
    background: #0cc161;
    position: relative;
    .close {
      color: #3e3f4f;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      font-size: 24px;
    }
    .top {
      height: 100px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .title {
        font-size: 24px;
        color: #333;
        text-align: center;
        margin-bottom: 5px;
        img {
          width: 30px;
          height: 30px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .time {
        color: red;
        font-size: 20px;
        letter-spacing: 2px;
      }
    }
    .bot {
      height: calc(100% - 100px);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 400px;
        height: 400px;
        border: 25px solid #fff;
        border-radius: 5px;
      }
    }
  }
}
</style>
